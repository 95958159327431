import GoogleAnalyticsProvider from './providers/google'
import ConsoleProvider from './providers/console'
import PlausibleProvider from './providers/plausible'

export const PROVIDERS = {
  GOOGLE_ANALYTICS: 'google_analytics',
  CONSOLE: 'console',
  PLAUSIBLE: 'plausible',
}

export const PROVIDER_LIBS = {
  [PROVIDERS.GOOGLE_ANALYTICS]: GoogleAnalyticsProvider,
  [PROVIDERS.CONSOLE]: ConsoleProvider,
  [PROVIDERS.PLAUSIBLE]: PlausibleProvider,
}

export class Analytics {
  providers = []

  constructor({ providers } = {}) {
    if (!providers?.length) {
      throw new Error('A providers list is required')
    }
    this.providers = providers.map(provider => PROVIDER_LIBS[provider])
    this.initialize()
  }

  initialize() {
    // initialize all providers
    for (const provider of this.providers) {
      const FUNC_NAME = 'initialize'
      if (typeof provider[FUNC_NAME] === 'function') {
        provider[FUNC_NAME].call(provider)
      }
    }
  }

  setGlobalProps(props) {
    for (const provider of this.providers) {
      const FUNC_NAME = 'setGlobalProps'
      if (typeof provider[FUNC_NAME] === 'function') {
        provider[FUNC_NAME].call(provider, props)
      }
    }
  }

  trackEvent(eventName, eventProps) {
    for (const provider of this.providers) {
      const FUNC_NAME = 'trackEvent'
      if (typeof provider[FUNC_NAME] === 'function') {
        provider[FUNC_NAME].call(provider, eventName, eventProps)
      }
    }
  }

  trackPage(eventProps) {
    for (const provider of this.providers) {
      const FUNC_NAME = 'trackPage'
      if (typeof provider[FUNC_NAME] === 'function') {
        provider[FUNC_NAME].call(provider, eventProps)
      }
    }
  }
}

export default Analytics

export const ANALYTIC_EVENTS = {
  PAGE_VIEW: 'page_view',
  DISPLAY_VIEW: ' display_view',
  VIEW_ITEM_LIST: 'view_item_list',
  SCROLL_ALTERNATIVE_PRODUCTS: 'scroll_alternative_products',
  HIDE_ALTERNATIVE_PRODUCTS: 'hide_alternative_products',
  HIDE_PRODUCT_LOCATIONS: 'hide_product_locations',
  SHOW_PRODUCT_LOCATIONS: 'show_product_locations',
  SELECT_PRODUCT: 'select_product',
  SELECT_SAME_PRODUCT: 'select_same_product',
  LEARN_MORE_ABOUT_PRODUCT: 'learn_more_about_product',
  CLICK_PRODUCT_CTA: 'click_product_cta',
}

import { createContext, useContext } from 'react'
import { Analytics, PROVIDERS } from './index'

const AnalyticsContext = createContext()

function getProvidersForEnvironment() {
  const { ENVIRONMENT } = window.HUBSTAIRS_CONFIG
  if (ENVIRONMENT === 'local') {
    return [PROVIDERS.CONSOLE]
  } else {
    return [PROVIDERS.PLAUSIBLE, PROVIDERS.GOOGLE_ANALYTICS]
  }
}

export const analytics = new Analytics({
  providers: getProvidersForEnvironment(),
})

export const AnalyticsProvider = function ({ children }) {
  return <AnalyticsContext.Provider value={{ analytics }}>{children} </AnalyticsContext.Provider>
}

export const useAnalytics = function () {
  const { analytics } = useContext(AnalyticsContext)
  return analytics
}

class GoogleAnalyticsProvider {
  globalProps = {}

  initialize() {
    if (!window.plausible) {
      const script = document.createElement('script')
      script.setAttribute('data-domain', window.location.hostname)
      script.src = `https://plausible.io/js/script.manual.js`
      script.defer = true
      document.head.appendChild(script)
      window.plausible = function () {
        ;(window.plausible.q = window.plausible.q || []).push(arguments)
      }
    }
  }

  setGlobalProps(props) {
    this.globalProps = props
  }

  trackEvent(eventName, eventProps) {
    return new Promise((resolve, reject) => {
      const props = {
        ...this.globalProps,
        ...eventProps,
      }
      window.plausible(eventName, { props })
      resolve(props)
    })
  }

  trackPage(eventProps) {
    return this.trackEvent('pageview', eventProps)
  }
}

export default new GoogleAnalyticsProvider()

import { ANALYTIC_EVENTS } from '../utils'

const { GA_MEASUREMENT_ID } = window.HUBSTAIRS_CONFIG

class GoogleAnalyticsProvider {
  initialize() {
    if (!window.gtag) {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`
      script.async = true
      document.head.appendChild(script)

      window.dataLayer = window.dataLayer || []
      window.gtag = function gtag() {
        window.dataLayer.push(arguments)
      }
      window.gtag('js', new Date())
      window.gtag('config', GA_MEASUREMENT_ID, { send_page_view: false })
    }
  }

  setGlobalProps(props) {
    window.gtag('config', GA_MEASUREMENT_ID, {
      ...props,
      send_page_view: false,
    })
  }

  trackEvent(eventName, eventProps) {
    return new Promise((resolve, reject) => {
      const props = {
        ...this.globalProps,
        ...eventProps,
        event_callback: function (data) {
          resolve(data)
        },
      }

      window.gtag('event', eventName, props)
    })
  }

  trackPage(eventProps) {
    return this.trackEvent(ANALYTIC_EVENTS.PAGE_VIEW, eventProps)
  }
}

export default new GoogleAnalyticsProvider()

class ConsoleProvider {
  globalProps = {}

  initialize() {
    // eslint-disable-next-line no-console
    console.log('initializing console provider')
  }

  setGlobalProps(props) {
    this.globalProps = props
  }

  trackEvent(eventName, eventProps) {
    // eslint-disable-next-line no-console
    console.log(`Event track -> ${eventName} -> props: `, { ...this.globalProps, ...eventProps })
  }

  trackPage(eventProps) {
    this.trackEvent('Page View', eventProps)
  }
}

export default new ConsoleProvider()

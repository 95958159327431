import 'react-app-polyfill/ie11'
import addPolyfills from './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { version } from '../package.json'
import { AnalyticsProvider } from './helpers/analytics/context'

const { SENTRY_DSN, ENVIRONMENT } = window.HUBSTAIRS_CONFIG

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: version,
  enabled: ['production'].includes(ENVIRONMENT),
})

const App = React.lazy(() => import(/* webpackChunkName: "application" */ './app'))

function runApp() {
  addPolyfills().then(render)
}

function render() {
  ReactDOM.render(
    <React.Suspense fallback={<div />}>
      <AnalyticsProvider>
        <App />
      </AnalyticsProvider>
    </React.Suspense>,
    document.body,
  )
}

runApp()

export default async function addPolyfills() {
  return await Promise.all([importURLSearchParams(), importResizeObserver()])
}

async function importURLSearchParams() {
  if (typeof window !== 'undefined' && !('URLSearchParams' in window)) {
    await import(/* webpackChunkName: "url-search-params-polyfill" */ 'url-search-params-polyfill')
  }
}

async function importResizeObserver() {
  if (!('ResizeObserver' in window)) {
    const ResizeObserver = await import(
      /* webpackChunkName: "pollyfill-resize-observer-polyfill" */ 'resize-observer-polyfill'
    )
    window.ResizeObserver = ResizeObserver.default
  }
}
